
$white:                     #ffffff !default;
//$dimmer:                    rgba(31, 49, 60, 0.85) !default;
$dimmer:                    rgba(17, 29, 42, 0.85) !default;
$black:                     #212529 !default;
$teach-black:               #293D60 !default;
$orange:                    #F06334 !default;
$orange-hover:              #fa3a0e !default;
$placeholder:               rgba(191, 191, 191, .87) !default;
$classCard-bg:              #eef3fb;
$amount-bg:                 #fff3f0;

$teach-black-disabled:      #bababa !default;

$teacher-qualification:     #b38171
