@font-face {font-family: "Gilroy Bold";
  src: url("../fonts/gilroy/Gilroy-Bold.otf"); /* IE9*/
  src: url("../fonts/gilroy/Gilroy-Bold.otf") format("opentype"); /* IE6-IE8 */
}
@font-face {font-family: "Gilroy Medium";
  src: url("../fonts/gilroy/Gilroy-Medium.otf"); /* IE9*/
  src: url("../fonts/gilroy/Gilroy-Medium.otf") format("opentype"); /* IE6-IE8 */
}
@font-face {font-family: "Gilroy Medium";
  src: url("../fonts/gilroy/Gilroy-Medium.otf"); /* IE9*/
  src: url("../fonts/gilroy/Gilroy-Medium.otf") format("opentype"); /* IE6-IE8 */
}
@font-face {font-family: "Gilroy Light";
  src: url("../fonts/gilroy/Gilroy-Light.otf"); /* IE9*/
  src: url("../fonts/gilroy/Gilroy-Light.otf") format("opentype"); /* IE6-IE8 */
}

//$common-fonts:      'Fira Sans', sans-serif!important;
//$common-fonts:      "Red Hat Display" !important;
$common-fonts:      "Gilroy Medium"!important;
